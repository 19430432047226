<template>
  <div>
    <div class="game-pay" v-if="payShow">
      <div class="game-pay-box">
        <div class="game-pay-title">
          <div class="game-pay-back">
            <span @click="close">
              <van-icon name="cross" />
            </span>
          </div>
          <div class="game-pay-t">
            游戏充值
          </div>
          <div class="game-pay-customer"></div>
        </div>
        <div class="game-information">
          <div class="game">充值游戏:{{ payC.gameUserName }}</div>
          <div class="role">充值角色:{{ payC.gameUserRole }}</div>
          <div class="amount">
            充值金额:<span>{{ payC.amount }}</span>
          </div>
          <div class="commodity">
            商品:<span>{{ payC.productId }}</span>
          </div>
        </div>
        <div class="divider">
          <van-divider>请选择支付方式</van-divider>
        </div>
        <div class="pay">
          <van-radio-group
            v-model="payVersion"
            v-for="(item, index) in filterPay"
            :key="item + index"
          >
            <div class="wechat-pays" @click="selectPay(item)">
              <div class="pay-box">
                <van-icon :name="item.icon" size="30px" :color="item.color" />
                <span>{{ item.title }}</span>
              </div>
              <van-radio :name="item.content"> </van-radio>
            </div>
          </van-radio-group>
        </div>
        <div class="pay-btn-box">
          <button @click="payfrom" class="pay-btn">
            <span class="pay-span">确认支付</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="loadingShow">
      <van-dialog
        v-model="show"
        title="温馨提示"
        confirmButtonText="完成支付"
        confirmButtonColor="black"
        @confirm="confirmBtn(payVersion)"
      >
      </van-dialog>
    </div>
    <a id="links" href="#" style="display:none;"></a>
  </div>
</template>
<script>
import { Toast } from 'vant';
import QRCode from 'qrcode';
import axios from 'axios';
import { _isMobile } from '@/util/equipment';
import {
  h5Pay,
  aliPayQuery,
  payCallback,
  h5Wechat,
  wechatPayQuery,
} from '@/service/moudules/wrapApi';
import { getLocalStorage, setLocalStorage } from '@/util/userLoginFn';
import { isSafari, isWechat, invokeWechat } from '@/util/eqUrl';
export default {
  data() {
    return {
      getLocalStorage,
      setLocalStorage,
      h5Pay,
      aliPayQuery,
      payCallback,
      wechatPayQuery,
      h5Wechat,
      isSafari,
      isWechat,
      invokeWechat,
      pcPay: [
        {
          icon: 'wechat-pay',
          title: '微信支付(扫码)',
          content: 'wechat-pay-pc',
          color: '#28c445',
        },
        {
          icon: 'alipay',
          title: '支付宝支付(扫码)',
          content: 'ali-pay-pc',
          color: '#06b4fd',
        },
      ],
      h5Pay: [
        {
          icon: 'wechat',
          title: '微信支付',
          content: 'wechat-pay-code',
          color: '#28c445',
        },
        {
          icon: 'alipay',
          title: '支付宝支付',
          content: 'ali-pay-code',
          color: '#06b4fd',
        },
      ],
      show: false,
      payIs: '',
      payVersion: '',
      filterPay: [],
      payC: {
        amount: 0,
        gameId: '',
        gameUserId: '',
        gameUserName: '',
        gameUserRole: '',
        gameZoneId: '',
        orderId: '',
        productId: '',
      },
      isPayh: '',
      payShow: true,
      loadingShow: false,
    };
  },
  methods: {
    close() {
      // this.loadingShow = true;
      // this.show = true;
      this.show = false;
      this.loadingShow = false;
      this.payShow = false;
      window.parent.postMessage({ data: 'close' }, '*');
    },
    cancelBtn() {
      this.show = false;
      this.loadingShow = false;
      this.payShow = false;
      window.parent.postMessage({ data: 'close' }, '*');
    },
    async confirmBtn(type) {
      try {
        console.log(type, 'type');
        let params = {
          orderId: this.payC.orderId,
        };
        let res;
        switch (type) {
          case 'wechat-pay-pc' || 'wechat-pay-code':
            res = await wechatPayQuery(params);
            if (res.code !== 200) {
              Toast.fail(res.msg);
              this.show = false;
              this.loadingShow = false;
              this.payShow = false;
              window.parent.postMessage({ data: 'close' }, '*');
              return false;
            }

            break;
          case 'ali-pay-pc' || 'ali-pay-code':
            res = await aliPayQuery(params);

            if (res.code !== 200) {
              Toast.fail(res.msg);
              this.show = false;
              this.loadingShow = false;
              this.payShow = false;
              window.parent.postMessage({ data: 'close' }, '*');
              return false;
            }

            break;
          default:
            break;
        }
        let AlipayForm = {
          orderId: this.payC.orderId,
          orderNo: this.payC.orderId,
          amount: this.payC.amount,
          uid: this.payC.gameUserId,
          orderStatus: res.data,
        };

        axios
          .post(
            `https://sdk.sh9130.com/partner/pay/754/tjqy_tjqylch5lj_ATK/?orderId=${AlipayForm.orderId}&orderNo=${AlipayForm.orderNo}&amount=${AlipayForm.amount}&uid=${AlipayForm.uid}&orderStatus=${AlipayForm.orderStatus}`
          )
          .then((res) => {
            this.cancelBtn();
          })
          .catch((error) => {
            this.cancelBtn();
            console.log(error, 'error');
          });
      } catch (error) {
        this.cancelBtn();
        console.log(error);
      }
    },

    async aliPayMethod(params) {
      let res = await h5Pay(params);
      if (res.code !== 200) {
        Toast.fail(res.msg);
        return false;
      }
      let div = document.querySelector('#payDiv');
      div = document.createElement('div');
      div.id = 'payDiv';
      document.body.appendChild(div);
      div.innerHTML = res.data;
      // if (this.isSafari()) {
      //   document.forms['punchout_form'].setAttribute('target', '_blank');
      //   document.forms['punchout_form'].submit();
      //   document.removeChild(div);

      //   return false;
      // }
      // document.forms['punchout_form'].setAttribute('accept-charset', "UTF-8");
      // document.forms['punchout_form'].setAttribute('οnsubmit', "document.charset='UTF-8'");

      setTimeout(() => {
        document.forms['punchout_form'].setAttribute('target', '_blank');
        document.forms[0].submit();
        document.removeChild(div);
      }, 0);
      // document.forms[0].submit();
      // div.remove();
    },

    async payfrom() {
      try {
        this.loadingShow = true;
        this.show = true;
        let {
          amount,
          gameId,
          gameUserId,
          gameUserName,
          gameUserRole,
          gameZoneId,
          orderId,
          productId,
        } = this.payC;
        let res;
        let params;
        let qrcUrl;
        let routeUrl;
        switch (this.payIs) {
          case 'wechat-pay-code':
            params = {
              gameUserId,
              gameUserName,
              gameZoneId,
              gameUserRole,
              amount,
              productId,
              gameId,
              orderId,
              paymentType: 'H5',
            };
            // window.open('', '_blank');

            res = await h5Wechat(params);
            if (res.code !== 200) {
              Toast.fail(res.msg);
              return false;
            }

            if (this.isWechat()) {
              if (typeof WeixinJSBridge == 'undefined') {
                if (document.addEventListener) {
                  document.addEventListener(
                    'WeixinJSBridgeReady',
                    this.invokeWechat(res.data),
                    false
                  );
                } else if (document.attachEvent) {
                  document.attachEvent(
                    'WeixinJSBridgeReady',
                    this.invokeWechat(res.data)
                  );
                  document.attachEvent(
                    'onWeixinJSBridgeReady',
                    this.invokeWechat(res.data)
                  );
                }
              } else {
                this.invokeWechat(res.data);
              }

              return false;
            }

            // window.location.href = res.data;

            // qrcUrl = await QRCode.toDataURL(res.data);

            // routeUrl = this.$router.resolve({
            //     path: '/wechatPayPc',
            //     query: {
            //         userName: gameUserName,
            //         amount,
            //         userOrderId: orderId,
            //         qrcUrl,
            //     },
            // });

            // window.open(routeUrl.href, '_self');

            let obj = document.getElementById('links');
            obj.target = '_blank';
            obj.href = res.data;
            //调用这个对象的click方法
            setTimeout(() => {
              obj.click();
            }, 0);

            break;
          case 'ali-pay-code':
            params = {
              gameUserId,
              gameUserName,
              gameZoneId,
              gameUserRole,
              amount,
              productId,
              gameId,
              orderId,
              // promotionId: this.getLocalStorage('inviteCode'),
              paymentType: 'QUICK_WAP_WAY',
            };

            this.aliPayMethod(params);

            break;

          case 'wechat-pay-pc':
            params = {
              gameUserId,
              gameUserName,
              gameZoneId,
              gameUserRole,
              amount,
              productId,
              gameId,
              orderId,
              paymentType: 'NATIVE',
            };

            res = await h5Wechat(params);
            if (res.code !== 200) {
              Toast.fail(res.msg);
              return false;
            }
            qrcUrl = await QRCode.toDataURL(res.data);

            routeUrl = this.$router.resolve({
              path: '/wechatPayPc',
              query: {
                userName: gameUserName,
                amount,
                userOrderId: orderId,
                qrcUrl,
              },
            });

            let wpc = document.getElementById('links');
            wpc.target = '_blank';
            wpc.href = routeUrl.href;
            //调用这个对象的click方法
            setTimeout(() => {
              wpc.click();
            }, 0);

            break;

          case 'ali-pay-pc':
            params = {
              gameUserId,
              gameUserName,
              gameZoneId,
              gameUserRole,
              amount,
              productId,
              gameId,
              orderId,
              // promotionId: this.getLocalStorage('inviteCode'),
              paymentType: 'FAST_INSTANT_TRADE_PAY',
            };

            this.aliPayMethod(params);

            if (res.code !== 200) {
              Toast.fail(msg);
              return false;
            }
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectPay(item) {
      this.payIs = item.content;
      this.payVersion = item.content;
    },
  },
  mounted() {
    if (_isMobile()) {
      this.filterPay = this.h5Pay.map((item) => {
        if (item.content.includes('code')) {
          item.version = 'h5';
        }
        return item;
      });
    } else {
      this.filterPay = this.pcPay.map((item) => {
        if (item.content.includes('pc')) {
          item.version = 'pc';
        }
        return item;
      });
    }
  },

  created() {
    let {
      amount,
      gameId,
      gameUserId,
      gameUserName,
      gameUserRole,
      gameZoneId,
      orderId,
      productId,
    } = this.$route.query;
    this.payC.amount = amount;
    this.payC.gameId = gameId;
    this.payC.gameUserId = gameUserId;
    this.payC.gameUserName = gameUserName;
    this.payC.gameUserRole = gameUserRole;
    this.payC.gameZoneId = gameZoneId;
    this.payC.orderId = orderId;
    this.payC.productId = productId;

    //
    // console.log("======当前域名======"+window.location.hostname)
    // if(window.location.hostname == "game.91shopping.net" || window.location.hostname == "games.91shopping.net"){
    //   var url = "http://gamess.91shopping.net/pay?gameUserId="+gameUserId+"&gameUserName="+gameUserName+"&gameZoneId="+gameZoneId+"&gameUserRole="+gameUserRole+"&amount="+amount+"&productId="+productId+"&gameId="+gameId+"&orderId="+orderId+"&paymentType=";
    //   console.log("======打开目标======"+url)
    // var a = $('<a href="'+url+'" target="_self"></a>')[0];
    // var e = document.createEvent('MouseEvents');
    // e.initEvent('click', true, true);
    // a.dispatchEvent(e);
    //   window.open(url, '_blank');
    // }
  },
};
</script>
<style lang="less" scoped>
.game-pay {
  height: 100vh;
  .game-pay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    height: 600px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    border: 2px solid;
    border-image: linear-gradient(
        224deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.33),
        rgba(255, 255, 255, 0.08)
      )
      2 2;
    .game-pay-title {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .game-pay-back {
      }
      .game-pay-t {
        font-size: 18px;
      }
      .game-pay-customer {
        font-size: 12px;
        color: #c94646;
      }
    }
    .game-information {
      padding: 10px;
      text-align: left;
      font-size: 16px;
      color: #000000;
      line-height: 30px;
      .amount {
        & span {
          color: #c94646;
        }
      }
    }
    .divider {
      .van-divider {
        color: #000;
      }
    }
    .pay {
      padding: 15px;
      .wechat-pays {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
      .pay-box {
        display: flex;
        align-items: center;
      }
    }
    .pay-btn-box {
      display: flex;
      justify-content: center;
      align-items: center;
      .pay-btn {
        border-radius: 23px;
        border: 1px solid;
        border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        background-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        margin-top: 20px;
        padding: 9px 110px 8px 110px;
        .pay-span {
          overflow-wrap: break-word;
          color: #fff;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;

          white-space: nowrap;
          line-height: 25px;
        }
      }
    }
  }
}
/deep/ .van-dialog__header {
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 10px;
}
</style>
